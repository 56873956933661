import React, { useState } from 'react';
import './TextInput.css'; // Make sure to style this file appropriately

function TextInput() {
  // State to hold the input value
  const [input, setInput] = useState('');

  // Function to handle input change
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  // Function to handle submit (e.g., send message)
  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      // Here, you'd typically send the message (input) to a chat backend or perform some action.
      console.log('Message sent:', input);
      setInput(''); // Clear the input after sending
    }
  };

  return (
    <form className="text-input-container" onSubmit={handleSubmit}>
      <input
        type="text"
        className="text-input"
        placeholder="Type your message..."
        value={input}
        onChange={handleInputChange}
      />
        <button type="submit" className="send-button" disabled={!input.trim()}>
        Send
        </button>

    </form>
  );
}

export default TextInput;
