import Navbar from './components/Navbar';
import TextInput from './components/TextInput';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';

function App() {
  return (
    <>
    <Router>
    <Navbar />
    <Routes>
      <Route path='/' exact />
    </Routes>
    <TextInput />

    </Router>
    </>
  );
}

export default App;
