// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-input-container {
    display: flex;
    align-items: center;
    width: 70vw; /* 70% of the viewport width */
    position: absolute; /* Absolute positioning */
    bottom: 6vh; /* Position it 80% of the way down (20vh from the bottom) */
    left: 50%; /* Center it horizontally */
    transform: translateX(-50%); /* Center it by adjusting the left by half its own width */
    padding: 10px;
    border-top: 1px solid #e0e0e0;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  
  .text-input {
    flex: 1 1;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ddd;
    font-size: 16px;
    outline: none;
    margin-right: 10px;
    transition: border-color 0.2s ease;
  }
  
  .text-input:focus {
    border-color: #f36527; /* Accent color (blue) */
  }
  
  .send-button {
    background-color: #f36527;
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .send-button:hover {
    background-color: #f36527; /* Darker blue on hover */
  }
  
  .send-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/TextInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW,EAAE,8BAA8B;IAC3C,kBAAkB,EAAE,yBAAyB;IAC7C,WAAW,EAAE,2DAA2D;IACxE,SAAS,EAAE,2BAA2B;IACtC,2BAA2B,EAAE,0DAA0D;IACvF,aAAa;IACb,6BAA6B;IAC7B,sBAAsB;IACtB,yCAAyC;IACzC,mBAAmB;EACrB;;EAEA;IACE,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,kCAAkC;EACpC;;EAEA;IACE,qBAAqB,EAAE,wBAAwB;EACjD;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB,EAAE,yBAAyB;EACtD;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;EACrB","sourcesContent":[".text-input-container {\n    display: flex;\n    align-items: center;\n    width: 70vw; /* 70% of the viewport width */\n    position: absolute; /* Absolute positioning */\n    bottom: 6vh; /* Position it 80% of the way down (20vh from the bottom) */\n    left: 50%; /* Center it horizontally */\n    transform: translateX(-50%); /* Center it by adjusting the left by half its own width */\n    padding: 10px;\n    border-top: 1px solid #e0e0e0;\n    background-color: #fff;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    border-radius: 20px;\n  }\n  \n  .text-input {\n    flex: 1;\n    padding: 10px;\n    border-radius: 20px;\n    border: 1px solid #ddd;\n    font-size: 16px;\n    outline: none;\n    margin-right: 10px;\n    transition: border-color 0.2s ease;\n  }\n  \n  .text-input:focus {\n    border-color: #f36527; /* Accent color (blue) */\n  }\n  \n  .send-button {\n    background-color: #f36527;\n    color: white;\n    border: none;\n    padding: 10px 16px;\n    border-radius: 20px;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.2s ease;\n  }\n  \n  .send-button:hover {\n    background-color: #f36527; /* Darker blue on hover */\n  }\n  \n  .send-button:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
